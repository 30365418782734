import React, { Component } from 'react';
import styles from './journeyCatalogueStyleB.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import JourneyCard from 'commonComponents/journeyCard';
import DefaultLoader from 'commonComponents/loaders/defaultLoader/DefaultLoader';
import JourneyIntroductionPopupContainer from 'containers/JourneyIntroductionPopupContainer';
import Option from 'commonComponents/customizedSelectOption';
import Select from "react-select";
import _ from 'lodash';
import NoJourneyErrorComponent from 'commonComponents/noJourneyErrorComponent';
import { isMobile } from 'react-device-detect';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })

class JourneyCatalogueStyleB extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedCategories: [],
			selectedFormats: [],
			searchText: "",
			showJourneyIntroductionPopup: false,
			selectedJourneyInstanceId: null
		}
		this.autoSearchJourneys = _.debounce(this.fetchSearchedJourneys, 800);
	}

	setJourneyIntroductionPopup = (selectedJourneyInstanceId) => {
		this.setState({
			showJourneyIntroductionPopup: true,
			selectedJourneyInstanceId: selectedJourneyInstanceId
		});
	}

	unsetJourneyIntroductionPopup = () => {
		this.setState({
			showJourneyIntroductionPopup: false,
			selectedJourneyInstanceId: null
		});
	}

	addOrRemoveFilterValue = (filter, value) => {
		let updatedFilter = value.map(obj => obj.value)
		this.setState({
			[filter]: updatedFilter
		});
	}

	handleSearch = (value) => {
		this.setState({
			searchText: value
		}, () => {
			this.autoSearchJourneys();
		});
	}

	fetchSearchedJourneys = () => {
		this.props.fetchJourneyCatalogueData(this.props.fromPublicCatalog, this.state.searchText)
	}

	getFilterComponent = (allValues, myStyles, keyName, valueName, filterName, stateFilterName, prefix) => {
		let filterOptions = [];
		const selfPacedOption = allValues.filter(value => value.get("key") === "SELF_PACED");
		const restOptions = allValues.filter(value => value.get("key") !== "SELF_PACED");
		selfPacedOption.forEach(value => {
			filterOptions.push(
				{
					value: value.get(keyName),
					label: this.props.getLabel(`${prefix}_${value.get(keyName)}_label`)
				}
			)
		});
		filterOptions.push(
			{
				value: restOptions.reduce((acc, value) => acc + value.get(keyName) + ",", ""),
				label: restOptions.reduce((acc, value) => {
					return acc + this.props.getLabel(`${prefix}_${value.get(keyName)}_label`) + "/";
				}, "").slice(0, -1)
			}
		);
		const customStyles = {
			option: (provided, state) => ({
				...provided,
				margin: '2px 0',
				backgroundColor: 'white',
				padding: '5px 10px'
			})
		}

		return (
			<div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
				<div className={css(myStyles.filterName)}>{filterName}:</div>
				<div style={ isMobile ? { maxWidth: '200px', minWidth: '200px', marginLeft: '10px' } : { maxWidth: '250px', minWidth: '250px', marginLeft: '15px' }}>
					<Select
						closeMenuOnSelect={false}
						isMulti
						components={{ Option }}
						options={filterOptions}
						hideSelectedOptions={false}
						backspaceRemovesValue={false}
						onChange={e => this.addOrRemoveFilterValue(stateFilterName, e)}
						placeholder={this.props.getLabel('filters_all_selected_label')}
						styles={customStyles}
					/>
				</div>
			</div>
		)
	}

	getFilterAndSearchComponent = (myStyles) => {
		const {
			journeyFormats,
			getLabel,
		} = this.props;

		const {
			searchText
		} = this.state;

		let journeyFormatFilterComponent = this.getFilterComponent(journeyFormats, myStyles, "key", "value", getLabel('journey_formats_label'), "selectedFormats", "format");

		return (
			<div styleName="journey-filters-and-search-cnt bdPassportSearchAndFilter">
				<div styleName="journey-filters-cnt" style={{ width: "unset" }}>
					{journeyFormatFilterComponent}
				</div>
				<div styleName="journey-search-cnt" style={{ margin: 0 }}>
					<input
						value={searchText}
						placeholder={getLabel('search_journeys_placeholder_label')}
						onChange={e => this.handleSearch(e.target.value)}
					/>
				</div>
			</div>
		)
	}

	getAvailableJourneyCards = (myStyles) => {
		const {
			catalogueJourneys,
			userOrganization,
			getLabel
		} = this.props;


		if (!catalogueJourneys.get('availableJourneys') || catalogueJourneys.get('isAvailableJourneysFetching')) {
			return (
				<div style={{ height: '600px', width: '1280px', margin: 'auto' }}>
					<DefaultLoader />
				</div>
			)
		}

		const {
			selectedFormats
		} = this.state;

		if (catalogueJourneys.get('availableJourneys').size === 0) {
			return (
				<div styleName="category-wise-journeys-cnt">
					<NoJourneyErrorComponent />
				</div>
			)
		}

		let filteredJourneys = catalogueJourneys.get("availableJourneys");
		
		if(selectedFormats.length > 0) {
			filteredJourneys = filteredJourneys.filter(batch => {
				const format = batch.getIn(["journey", "format"]);

				if(format) {
					for(let i = 0; i < selectedFormats.length; i++) {
						if(selectedFormats[i].includes(format.get("key"))) {
							return true;
						}
					}
				}

				return false;
			})
		}

		const journeysComponent = [];
		const journeyCards = filteredJourneys.map(journey => {
			return (
				<JourneyCard
					key={`completed_active_batch_${journey.get('id')}`}
					batchDetails={journey}
					isAvailable={true}
					setJourneyIntroductionPopup={this.setJourneyIntroductionPopup}
					userOrganization={userOrganization}
					isBDOrg={true}
				/>
			);
		});

		if(journeyCards.size > 0) {
			journeysComponent.push(
				<div key={`category_journeys_${getLabel("uncategorized_journeys_heading_label")}`} styleName="category-wise-journeys-cnt">
					<div className={css(myStyles.categoryName)}>
						{getLabel("uncategorized_journeys_heading_label")}
					</div>
					<div styleName="journey-cards-cnt">
						{journeyCards}
					</div>
				</div>
			);
		}

		if (journeysComponent.length === 0) {
			return (
				<div styleName="category-wise-journeys-cnt">
					<NoJourneyErrorComponent />
				</div>
			)
		}

		return journeysComponent;
	}

	render() {
		const {
			showJourneyIntroductionPopup,
			selectedJourneyInstanceId,
			searchText
		} = this.state;

		const myStyles = getSkin(this.props.skinGuide);

		return (
			<div styleName="journey-catalogue-cnt">
				{this.getFilterAndSearchComponent(myStyles)}
				{this.getAvailableJourneyCards(myStyles)}
				{
					showJourneyIntroductionPopup &&
					<JourneyIntroductionPopupContainer
						journeyInstanceId={selectedJourneyInstanceId}
						closePopup={this.unsetJourneyIntroductionPopup}
						alwaysSignUp={this.props.alwaysSignUp}
						searchText={searchText}
					/>
				}
			</div>
		);
	}
}

export default JourneyCatalogueStyleB;