import store from "store/store";
import environment from "utils/environment";
import commondbVariants from 'constants/commondbVariants';

const getOrganizationLocale = () => {
  return store
    .getState()
    .getIn(["userOrganization", "userOrganization", "lang"]);
};

const getOrganizationId = () => {
  return store.getState().getIn(["userOrganization", "userOrganization", "id"]);
};

const getOrgCommondbVariant = () => {
   const langCode = getOrganizationLocale();
   return commondbVariants.get(langCode) || 'default';
}

const getOrgName = () => {
  return store.getState().getIn(["userOrganization", "userOrganization", "name"]);
}

const isOrgInFastEnrollList = (orgId) => {
  try {
    const fastEnrollOrgArray = JSON.parse(
      environment.REACT_APP_FAST_ENROLL_ORG_ARRAY
    );
    if (fastEnrollOrgArray) {
      return fastEnrollOrgArray.includes(orgId);
    }
  } catch (error) {
    return false;
  }
  return false;
};

export {
    getOrganizationLocale,
    getOrganizationId,
    getOrgCommondbVariant,
    isOrgInFastEnrollList,
    getOrgName
}
